.reactionButton {
  background-color: #2a2b2d;
  color: white;
  border: none;
  cursor: pointer;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  font-size: 1.2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.3s;
}

.reactionButton:hover {
  background-color: #515154;
}

.reactionButton:focus {
  outline: none;
}

.hideButton {
  color: #90caf9;
  white-space: nowrap;
  padding: 6px 8px;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.02857em;
  border: none;
  background-color: transparent;
  cursor: pointer;
  outline: none;
  border-radius: 4px;
  overflow: hidden;
  transform: rotate(90deg);
}

.hideButton:hover {
  background-color: linear-gradient(to bottom, #3f3f3f, #232323);
}

.hideButton:focus-visible {
  box-shadow: 0 0 0 2px rgba(0, 128, 255, 0.5);
}

@keyframes parabolicFloat {
  0% {
    transform: translateY(0) translateX(0);
    opacity: 1;
  }
  50% {
    transform: translateY(calc(var(--randomY) * -1px))
      translateX(calc(15px * var(--randomDirection)))
      rotate(calc(-45deg * var(--randomDirection)));
  }
  100% {
    transform: translateY(-100px) translateX(0);
    opacity: 0;
  }
}

.floating-reaction {
  position: absolute;
  pointer-events: none;
  font-size: 18px;
  color: #333;
  opacity: 1;
  z-index: 2000;
  animation: parabolicFloat 1.2s cubic-bezier(0.4, 0, 0.2, 1) forwards;
}

/* Media query for mobile phones */
@media (max-width: 768px) {
  .reactionButton {
    width: 30px;
    height: 30px;
    font-size: 1rem;
  }
}
