:root{
  --border: 1px;
  --logo-font-size: 6rem;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'Tektur';
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}


.background-clip {
  background-clip: text;
  -webkit-background-clip: text;
  -moz-background-clip: text;
}

.logo-font{
  font-family: "rocket";
  font-size: var(--logo-font-size) !important;
  line-height: calc(var(--logo-font-size) * .9) !important;
}

.logo-font span{
  display: block;
  line-height: calc(var(--logo-font-size) * .7) !important;
  font-size: calc(var(--logo-font-size) * .43) !important;
}

.gradient-text {
  background-color: #e2e2e2;
  background-image: linear-gradient(180deg, 
  #555 4%,  #90caf9 12.5%,  #555 27%, 
  #000 25% 28%, #999 40% 45%, #fff 60%, #999 85% 90%,#000 97% 100%
  );
  background-size: 100%;
  -webkit-text-fill-color: transparent; 
  -moz-text-fill-color: transparent;
  -webkit-text-stroke-width: 3px;
  -webkit-text-stroke-color: rgba(0,0,0,.7);
}

.gradient-text span {
  -webkit-text-stroke-width: 2px;
}

.gradient-text-border {
  background-clip: unset;
  -webkit-background-clip: unset;
  -moz-background-clip: unset;
  color:rgba(255,255,255,0);
  -webkit-text-stroke-width: 6px;
  -webkit-text-stroke-color: rgba(255,255,255,.3);
  text-shadow: 0px 0px 10px rgba(117, 117, 117, 0.1);
}

.gradient-text-border span{
  -webkit-text-stroke-width: .75px !important;
  -webkit-text-stroke-color: rgba(255,255,255,.6);
}

.animate-pulse {
  animation: pulse 2s cubic-bezier(0.4, 0, 0.61, 1) infinite;

}

@keyframes pulse {
  0%, 100% {
    opacity: 1;
  }
  50% {
    opacity: .5;
  }
}

@keyframes spinDice {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(888deg);
  }
}

@media (max-width: 600px) {
  :root{
    --logo-font-size: 5.5rem;
  }
}

@media (max-width: 500px) {
  :root{
    --logo-font-size: 5rem;
  }
}

@media (max-width: 400px) {
  :root{
    --logo-font-size: 4rem;
  }
}
@media (max-width: 350px) {
  :root{
    --logo-font-size: 3rem;
  }
}
@media (max-width: 250px) {
  :root{
    --logo-font-size: 2.5rem;
  }
}

