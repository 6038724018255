/* VideoBackground.css */
.video-container {
  position: relative;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}

.background-video {
  position: absolute;
  top: 50%;
  left: 50%;
  min-width: 100%;
  min-height: 100%;
  width: auto;
  height: auto;
  z-index: -1;
  transform: translate(-50%, -50%);
  filter: grayscale();  
  object-fit: cover;
  box-sizing: border-box;
}

.red-filter {
  filter: sepia(100%) saturate(800%) hue-rotate(-65deg);
  mix-blend-mode: hard-light;
}
.content {
  overflow: auto;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  /* display: flex;
  align-items: center;
  justify-content: center; */
  /* Add additional styling as needed */
}
